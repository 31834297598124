import { default as _91_46_46_46pageSlug_938rlFIn3PZiMeta } from "/home/forge/deployments/kinoheld/dev/2024-11-13_11-09-55_7e8ca1b/layers/cms/pages/[...pageSlug].vue?macro=true";
import { default as activatej4YG5dnXxCMeta } from "/home/forge/deployments/kinoheld/dev/2024-11-13_11-09-55_7e8ca1b/layers/base/pages/account/activate.vue?macro=true";
import { default as indexB9lEkLkuG2Meta } from "/home/forge/deployments/kinoheld/dev/2024-11-13_11-09-55_7e8ca1b/layers/base/pages/account/index.vue?macro=true";
import { default as _91securityKey_932iBeHKYJxHMeta } from "/home/forge/deployments/kinoheld/dev/2024-11-13_11-09-55_7e8ca1b/layers/base/pages/account/order/[orderNumber]/[securityKey].vue?macro=true";
import { default as newrLM4z38cW5Meta } from "/home/forge/deployments/kinoheld/dev/2024-11-13_11-09-55_7e8ca1b/layers/base/pages/account/password/new.vue?macro=true";
import { default as watchlisthl1vA332gMMeta } from "/home/forge/deployments/kinoheld/dev/2024-11-13_11-09-55_7e8ca1b/layers/base/pages/account/watchlist.vue?macro=true";
import { default as faqL3TFaLqzUTMeta } from "/home/forge/deployments/kinoheld/dev/2024-11-13_11-09-55_7e8ca1b/layers/portal/pages/cinema/[citySlug]/[cinemaSlug]/faq.vue?macro=true";
import { default as indexFWCVYAYQbPMeta } from "/home/forge/deployments/kinoheld/dev/2024-11-13_11-09-55_7e8ca1b/layers/portal/pages/cinema/[citySlug]/[cinemaSlug]/index.vue?macro=true";
import { default as infoP9KNoLUsmVMeta } from "/home/forge/deployments/kinoheld/dev/2024-11-13_11-09-55_7e8ca1b/layers/portal/pages/cinema/[citySlug]/[cinemaSlug]/info.vue?macro=true";
import { default as _91showGroupSlug_93oAEJBv5UZYMeta } from "/home/forge/deployments/kinoheld/dev/2024-11-13_11-09-55_7e8ca1b/layers/portal/pages/cinema/[citySlug]/[cinemaSlug]/movie/[showGroupSlug].vue?macro=true";
import { default as programaC6ciVzYglMeta } from "/home/forge/deployments/kinoheld/dev/2024-11-13_11-09-55_7e8ca1b/layers/portal/pages/cinema/[citySlug]/[cinemaSlug]/program.vue?macro=true";
import { default as shopdi7J0bkFLGMeta } from "/home/forge/deployments/kinoheld/dev/2024-11-13_11-09-55_7e8ca1b/layers/portal/pages/cinema/[citySlug]/[cinemaSlug]/shop.vue?macro=true";
import { default as _91showName_93Lqtnd69T5jMeta } from "/home/forge/deployments/kinoheld/dev/2024-11-13_11-09-55_7e8ca1b/layers/portal/pages/cinema/[citySlug]/[cinemaSlug]/show/[showSlug]/[showName].vue?macro=true";
import { default as _91showSlug_93ByBaPKu6QFMeta } from "/home/forge/deployments/kinoheld/dev/2024-11-13_11-09-55_7e8ca1b/layers/portal/pages/cinema/[citySlug]/[cinemaSlug]/show/[showSlug].vue?macro=true";
import { default as vouchersu6JCXdVq7CMeta } from "/home/forge/deployments/kinoheld/dev/2024-11-13_11-09-55_7e8ca1b/layers/portal/pages/cinema/[citySlug]/[cinemaSlug]/vouchers.vue?macro=true";
import { default as _91cinemaSlug_93YePGbqbiCHMeta } from "/home/forge/deployments/kinoheld/dev/2024-11-13_11-09-55_7e8ca1b/layers/portal/pages/cinema/[citySlug]/[cinemaSlug].vue?macro=true";
import { default as index51WICdNTytMeta } from "/home/forge/deployments/kinoheld/dev/2024-11-13_11-09-55_7e8ca1b/layers/portal/pages/cinema/[citySlug]/index.vue?macro=true";
import { default as _91citySlug_93El5xNHuK5bMeta } from "/home/forge/deployments/kinoheld/dev/2024-11-13_11-09-55_7e8ca1b/layers/portal/pages/cinema/[citySlug].vue?macro=true";
import { default as cinemasKQA8ATgvE0Meta } from "/home/forge/deployments/kinoheld/dev/2024-11-13_11-09-55_7e8ca1b/layers/portal/pages/cinemas.vue?macro=true";
import { default as citiesfXlSa72IQDMeta } from "/home/forge/deployments/kinoheld/dev/2024-11-13_11-09-55_7e8ca1b/layers/portal/pages/cities.vue?macro=true";
import { default as index80GEibO8LBMeta } from "/home/forge/deployments/kinoheld/dev/2024-11-13_11-09-55_7e8ca1b/layers/portal/pages/index.vue?macro=true";
import { default as _91movieSlug_938mKGmmmravMeta } from "/home/forge/deployments/kinoheld/dev/2024-11-13_11-09-55_7e8ca1b/layers/portal/pages/movie/[movieSlug].vue?macro=true";
import { default as index9zFmQR3TS0Meta } from "/home/forge/deployments/kinoheld/dev/2024-11-13_11-09-55_7e8ca1b/layers/portal/pages/movies/genre/[genreSlug]/index.vue?macro=true";
import { default as _91genreSlug_93oyRAu5w2rQMeta } from "/home/forge/deployments/kinoheld/dev/2024-11-13_11-09-55_7e8ca1b/layers/portal/pages/movies/genre/[genreSlug].vue?macro=true";
import { default as genresD3p9tyCm0kMeta } from "/home/forge/deployments/kinoheld/dev/2024-11-13_11-09-55_7e8ca1b/layers/portal/pages/movies/genres.vue?macro=true";
import { default as indexQ5mt30XBCkMeta } from "/home/forge/deployments/kinoheld/dev/2024-11-13_11-09-55_7e8ca1b/layers/portal/pages/movies/index.vue?macro=true";
import { default as indexmaLdkGxXMuMeta } from "/home/forge/deployments/kinoheld/dev/2024-11-13_11-09-55_7e8ca1b/layers/portal/pages/person/[personSlug]/index.vue?macro=true";
import { default as _91personSlug_93MrPQzxEk41Meta } from "/home/forge/deployments/kinoheld/dev/2024-11-13_11-09-55_7e8ca1b/layers/portal/pages/person/[personSlug].vue?macro=true";
import { default as personsnu0MamZ7ZrMeta } from "/home/forge/deployments/kinoheld/dev/2024-11-13_11-09-55_7e8ca1b/layers/portal/pages/persons.vue?macro=true";
import { default as searchCHMOEJm4KSMeta } from "/home/forge/deployments/kinoheld/dev/2024-11-13_11-09-55_7e8ca1b/layers/portal/pages/search.vue?macro=true";
import { default as vouchersR434f4AAQ4Meta } from "/home/forge/deployments/kinoheld/dev/2024-11-13_11-09-55_7e8ca1b/layers/portal/pages/vouchers.vue?macro=true";
export default [
  {
    name: "pageSlug___de",
    path: "/:pageSlug(.*)*",
    component: () => import("/home/forge/deployments/kinoheld/dev/2024-11-13_11-09-55_7e8ca1b/layers/cms/pages/[...pageSlug].vue").then(m => m.default || m)
  },
  {
    name: "pageSlug___es",
    path: "/es/:pageSlug(.*)*",
    component: () => import("/home/forge/deployments/kinoheld/dev/2024-11-13_11-09-55_7e8ca1b/layers/cms/pages/[...pageSlug].vue").then(m => m.default || m)
  },
  {
    name: "pageSlug___de",
    path: "/:pageSlug(.*)*",
    component: () => import("/home/forge/deployments/kinoheld/dev/2024-11-13_11-09-55_7e8ca1b/layers/cms/pages/[...pageSlug].vue").then(m => m.default || m)
  },
  {
    name: "pageSlug___es",
    path: "/es/:pageSlug(.*)*",
    component: () => import("/home/forge/deployments/kinoheld/dev/2024-11-13_11-09-55_7e8ca1b/layers/cms/pages/[...pageSlug].vue").then(m => m.default || m)
  },
  {
    name: "pageSlug___de",
    path: "/:pageSlug(.*)*",
    component: () => import("/home/forge/deployments/kinoheld/dev/2024-11-13_11-09-55_7e8ca1b/layers/cms/pages/[...pageSlug].vue").then(m => m.default || m)
  },
  {
    name: "pageSlug___es",
    path: "/es/:pageSlug(.*)*",
    component: () => import("/home/forge/deployments/kinoheld/dev/2024-11-13_11-09-55_7e8ca1b/layers/cms/pages/[...pageSlug].vue").then(m => m.default || m)
  },
  {
    name: "pageSlug___de",
    path: "/:pageSlug(.*)*",
    component: () => import("/home/forge/deployments/kinoheld/dev/2024-11-13_11-09-55_7e8ca1b/layers/cms/pages/[...pageSlug].vue").then(m => m.default || m)
  },
  {
    name: "pageSlug___es",
    path: "/es/:pageSlug(.*)*",
    component: () => import("/home/forge/deployments/kinoheld/dev/2024-11-13_11-09-55_7e8ca1b/layers/cms/pages/[...pageSlug].vue").then(m => m.default || m)
  },
  {
    name: "pageSlug___de",
    path: "/:pageSlug(.*)*",
    component: () => import("/home/forge/deployments/kinoheld/dev/2024-11-13_11-09-55_7e8ca1b/layers/cms/pages/[...pageSlug].vue").then(m => m.default || m)
  },
  {
    name: "pageSlug___es",
    path: "/es/:pageSlug(.*)*",
    component: () => import("/home/forge/deployments/kinoheld/dev/2024-11-13_11-09-55_7e8ca1b/layers/cms/pages/[...pageSlug].vue").then(m => m.default || m)
  },
  {
    name: "account-activate___de",
    path: "/konto/aktivieren",
    component: () => import("/home/forge/deployments/kinoheld/dev/2024-11-13_11-09-55_7e8ca1b/layers/base/pages/account/activate.vue").then(m => m.default || m)
  },
  {
    name: "account-activate___es",
    path: "/es/cuenta/activar",
    component: () => import("/home/forge/deployments/kinoheld/dev/2024-11-13_11-09-55_7e8ca1b/layers/base/pages/account/activate.vue").then(m => m.default || m)
  },
  {
    name: "account-activate___de",
    path: "/konto/aktivieren",
    component: () => import("/home/forge/deployments/kinoheld/dev/2024-11-13_11-09-55_7e8ca1b/layers/base/pages/account/activate.vue").then(m => m.default || m)
  },
  {
    name: "account-activate___es",
    path: "/es/cuenta/activar",
    component: () => import("/home/forge/deployments/kinoheld/dev/2024-11-13_11-09-55_7e8ca1b/layers/base/pages/account/activate.vue").then(m => m.default || m)
  },
  {
    name: "account-activate___de",
    path: "/konto/aktivieren",
    component: () => import("/home/forge/deployments/kinoheld/dev/2024-11-13_11-09-55_7e8ca1b/layers/base/pages/account/activate.vue").then(m => m.default || m)
  },
  {
    name: "account-activate___es",
    path: "/es/cuenta/activar",
    component: () => import("/home/forge/deployments/kinoheld/dev/2024-11-13_11-09-55_7e8ca1b/layers/base/pages/account/activate.vue").then(m => m.default || m)
  },
  {
    name: "account-activate___de",
    path: "/konto/aktivieren",
    component: () => import("/home/forge/deployments/kinoheld/dev/2024-11-13_11-09-55_7e8ca1b/layers/base/pages/account/activate.vue").then(m => m.default || m)
  },
  {
    name: "account-activate___es",
    path: "/es/cuenta/activar",
    component: () => import("/home/forge/deployments/kinoheld/dev/2024-11-13_11-09-55_7e8ca1b/layers/base/pages/account/activate.vue").then(m => m.default || m)
  },
  {
    name: "account-activate___de",
    path: "/konto/aktivieren",
    component: () => import("/home/forge/deployments/kinoheld/dev/2024-11-13_11-09-55_7e8ca1b/layers/base/pages/account/activate.vue").then(m => m.default || m)
  },
  {
    name: "account-activate___es",
    path: "/es/cuenta/activar",
    component: () => import("/home/forge/deployments/kinoheld/dev/2024-11-13_11-09-55_7e8ca1b/layers/base/pages/account/activate.vue").then(m => m.default || m)
  },
  {
    name: "account___de",
    path: "/konto",
    component: () => import("/home/forge/deployments/kinoheld/dev/2024-11-13_11-09-55_7e8ca1b/layers/base/pages/account/index.vue").then(m => m.default || m)
  },
  {
    name: "account___es",
    path: "/es/cuenta",
    component: () => import("/home/forge/deployments/kinoheld/dev/2024-11-13_11-09-55_7e8ca1b/layers/base/pages/account/index.vue").then(m => m.default || m)
  },
  {
    name: "account___de",
    path: "/konto",
    component: () => import("/home/forge/deployments/kinoheld/dev/2024-11-13_11-09-55_7e8ca1b/layers/base/pages/account/index.vue").then(m => m.default || m)
  },
  {
    name: "account___es",
    path: "/es/cuenta",
    component: () => import("/home/forge/deployments/kinoheld/dev/2024-11-13_11-09-55_7e8ca1b/layers/base/pages/account/index.vue").then(m => m.default || m)
  },
  {
    name: "account___de",
    path: "/konto",
    component: () => import("/home/forge/deployments/kinoheld/dev/2024-11-13_11-09-55_7e8ca1b/layers/base/pages/account/index.vue").then(m => m.default || m)
  },
  {
    name: "account___es",
    path: "/es/cuenta",
    component: () => import("/home/forge/deployments/kinoheld/dev/2024-11-13_11-09-55_7e8ca1b/layers/base/pages/account/index.vue").then(m => m.default || m)
  },
  {
    name: "account___de",
    path: "/konto",
    component: () => import("/home/forge/deployments/kinoheld/dev/2024-11-13_11-09-55_7e8ca1b/layers/base/pages/account/index.vue").then(m => m.default || m)
  },
  {
    name: "account___es",
    path: "/es/cuenta",
    component: () => import("/home/forge/deployments/kinoheld/dev/2024-11-13_11-09-55_7e8ca1b/layers/base/pages/account/index.vue").then(m => m.default || m)
  },
  {
    name: "account___de",
    path: "/konto",
    component: () => import("/home/forge/deployments/kinoheld/dev/2024-11-13_11-09-55_7e8ca1b/layers/base/pages/account/index.vue").then(m => m.default || m)
  },
  {
    name: "account___es",
    path: "/es/cuenta",
    component: () => import("/home/forge/deployments/kinoheld/dev/2024-11-13_11-09-55_7e8ca1b/layers/base/pages/account/index.vue").then(m => m.default || m)
  },
  {
    name: "account-order-orderNumber-securityKey___de",
    path: "/konto/bestellung/:orderNumber/:securityKey",
    component: () => import("/home/forge/deployments/kinoheld/dev/2024-11-13_11-09-55_7e8ca1b/layers/base/pages/account/order/[orderNumber]/[securityKey].vue").then(m => m.default || m)
  },
  {
    name: "account-order-orderNumber-securityKey___es",
    path: "/es/cuenta/compra/:orderNumber/:securityKey",
    component: () => import("/home/forge/deployments/kinoheld/dev/2024-11-13_11-09-55_7e8ca1b/layers/base/pages/account/order/[orderNumber]/[securityKey].vue").then(m => m.default || m)
  },
  {
    name: "account-order-orderNumber-securityKey___de",
    path: "/konto/bestellung/:orderNumber/:securityKey",
    component: () => import("/home/forge/deployments/kinoheld/dev/2024-11-13_11-09-55_7e8ca1b/layers/base/pages/account/order/[orderNumber]/[securityKey].vue").then(m => m.default || m)
  },
  {
    name: "account-order-orderNumber-securityKey___es",
    path: "/es/cuenta/compra/:orderNumber/:securityKey",
    component: () => import("/home/forge/deployments/kinoheld/dev/2024-11-13_11-09-55_7e8ca1b/layers/base/pages/account/order/[orderNumber]/[securityKey].vue").then(m => m.default || m)
  },
  {
    name: "account-order-orderNumber-securityKey___de",
    path: "/konto/bestellung/:orderNumber/:securityKey",
    component: () => import("/home/forge/deployments/kinoheld/dev/2024-11-13_11-09-55_7e8ca1b/layers/base/pages/account/order/[orderNumber]/[securityKey].vue").then(m => m.default || m)
  },
  {
    name: "account-order-orderNumber-securityKey___es",
    path: "/es/cuenta/compra/:orderNumber/:securityKey",
    component: () => import("/home/forge/deployments/kinoheld/dev/2024-11-13_11-09-55_7e8ca1b/layers/base/pages/account/order/[orderNumber]/[securityKey].vue").then(m => m.default || m)
  },
  {
    name: "account-order-orderNumber-securityKey___de",
    path: "/konto/bestellung/:orderNumber/:securityKey",
    component: () => import("/home/forge/deployments/kinoheld/dev/2024-11-13_11-09-55_7e8ca1b/layers/base/pages/account/order/[orderNumber]/[securityKey].vue").then(m => m.default || m)
  },
  {
    name: "account-order-orderNumber-securityKey___es",
    path: "/es/cuenta/compra/:orderNumber/:securityKey",
    component: () => import("/home/forge/deployments/kinoheld/dev/2024-11-13_11-09-55_7e8ca1b/layers/base/pages/account/order/[orderNumber]/[securityKey].vue").then(m => m.default || m)
  },
  {
    name: "account-order-orderNumber-securityKey___de",
    path: "/konto/bestellung/:orderNumber/:securityKey",
    component: () => import("/home/forge/deployments/kinoheld/dev/2024-11-13_11-09-55_7e8ca1b/layers/base/pages/account/order/[orderNumber]/[securityKey].vue").then(m => m.default || m)
  },
  {
    name: "account-order-orderNumber-securityKey___es",
    path: "/es/cuenta/compra/:orderNumber/:securityKey",
    component: () => import("/home/forge/deployments/kinoheld/dev/2024-11-13_11-09-55_7e8ca1b/layers/base/pages/account/order/[orderNumber]/[securityKey].vue").then(m => m.default || m)
  },
  {
    name: "account-password-new___de",
    path: "/konto/passwort/neu",
    component: () => import("/home/forge/deployments/kinoheld/dev/2024-11-13_11-09-55_7e8ca1b/layers/base/pages/account/password/new.vue").then(m => m.default || m)
  },
  {
    name: "account-password-new___es",
    path: "/es/cuenta/contrasena/nueva",
    component: () => import("/home/forge/deployments/kinoheld/dev/2024-11-13_11-09-55_7e8ca1b/layers/base/pages/account/password/new.vue").then(m => m.default || m)
  },
  {
    name: "account-password-new___de",
    path: "/konto/passwort/neu",
    component: () => import("/home/forge/deployments/kinoheld/dev/2024-11-13_11-09-55_7e8ca1b/layers/base/pages/account/password/new.vue").then(m => m.default || m)
  },
  {
    name: "account-password-new___es",
    path: "/es/cuenta/contrasena/nueva",
    component: () => import("/home/forge/deployments/kinoheld/dev/2024-11-13_11-09-55_7e8ca1b/layers/base/pages/account/password/new.vue").then(m => m.default || m)
  },
  {
    name: "account-password-new___de",
    path: "/konto/passwort/neu",
    component: () => import("/home/forge/deployments/kinoheld/dev/2024-11-13_11-09-55_7e8ca1b/layers/base/pages/account/password/new.vue").then(m => m.default || m)
  },
  {
    name: "account-password-new___es",
    path: "/es/cuenta/contrasena/nueva",
    component: () => import("/home/forge/deployments/kinoheld/dev/2024-11-13_11-09-55_7e8ca1b/layers/base/pages/account/password/new.vue").then(m => m.default || m)
  },
  {
    name: "account-password-new___de",
    path: "/konto/passwort/neu",
    component: () => import("/home/forge/deployments/kinoheld/dev/2024-11-13_11-09-55_7e8ca1b/layers/base/pages/account/password/new.vue").then(m => m.default || m)
  },
  {
    name: "account-password-new___es",
    path: "/es/cuenta/contrasena/nueva",
    component: () => import("/home/forge/deployments/kinoheld/dev/2024-11-13_11-09-55_7e8ca1b/layers/base/pages/account/password/new.vue").then(m => m.default || m)
  },
  {
    name: "account-password-new___de",
    path: "/konto/passwort/neu",
    component: () => import("/home/forge/deployments/kinoheld/dev/2024-11-13_11-09-55_7e8ca1b/layers/base/pages/account/password/new.vue").then(m => m.default || m)
  },
  {
    name: "account-password-new___es",
    path: "/es/cuenta/contrasena/nueva",
    component: () => import("/home/forge/deployments/kinoheld/dev/2024-11-13_11-09-55_7e8ca1b/layers/base/pages/account/password/new.vue").then(m => m.default || m)
  },
  {
    name: "account-watchlist___de",
    path: "/konto/merkliste",
    component: () => import("/home/forge/deployments/kinoheld/dev/2024-11-13_11-09-55_7e8ca1b/layers/base/pages/account/watchlist.vue").then(m => m.default || m)
  },
  {
    name: "account-watchlist___es",
    path: "/es/cuenta/marcadores",
    component: () => import("/home/forge/deployments/kinoheld/dev/2024-11-13_11-09-55_7e8ca1b/layers/base/pages/account/watchlist.vue").then(m => m.default || m)
  },
  {
    name: "account-watchlist___de",
    path: "/konto/merkliste",
    component: () => import("/home/forge/deployments/kinoheld/dev/2024-11-13_11-09-55_7e8ca1b/layers/base/pages/account/watchlist.vue").then(m => m.default || m)
  },
  {
    name: "account-watchlist___es",
    path: "/es/cuenta/marcadores",
    component: () => import("/home/forge/deployments/kinoheld/dev/2024-11-13_11-09-55_7e8ca1b/layers/base/pages/account/watchlist.vue").then(m => m.default || m)
  },
  {
    name: "account-watchlist___de",
    path: "/konto/merkliste",
    component: () => import("/home/forge/deployments/kinoheld/dev/2024-11-13_11-09-55_7e8ca1b/layers/base/pages/account/watchlist.vue").then(m => m.default || m)
  },
  {
    name: "account-watchlist___es",
    path: "/es/cuenta/marcadores",
    component: () => import("/home/forge/deployments/kinoheld/dev/2024-11-13_11-09-55_7e8ca1b/layers/base/pages/account/watchlist.vue").then(m => m.default || m)
  },
  {
    name: "account-watchlist___de",
    path: "/konto/merkliste",
    component: () => import("/home/forge/deployments/kinoheld/dev/2024-11-13_11-09-55_7e8ca1b/layers/base/pages/account/watchlist.vue").then(m => m.default || m)
  },
  {
    name: "account-watchlist___es",
    path: "/es/cuenta/marcadores",
    component: () => import("/home/forge/deployments/kinoheld/dev/2024-11-13_11-09-55_7e8ca1b/layers/base/pages/account/watchlist.vue").then(m => m.default || m)
  },
  {
    name: "account-watchlist___de",
    path: "/konto/merkliste",
    component: () => import("/home/forge/deployments/kinoheld/dev/2024-11-13_11-09-55_7e8ca1b/layers/base/pages/account/watchlist.vue").then(m => m.default || m)
  },
  {
    name: "account-watchlist___es",
    path: "/es/cuenta/marcadores",
    component: () => import("/home/forge/deployments/kinoheld/dev/2024-11-13_11-09-55_7e8ca1b/layers/base/pages/account/watchlist.vue").then(m => m.default || m)
  },
  {
    name: _91citySlug_93El5xNHuK5bMeta?.name,
    path: "/kino/:citySlug",
    component: () => import("/home/forge/deployments/kinoheld/dev/2024-11-13_11-09-55_7e8ca1b/layers/portal/pages/cinema/[citySlug].vue").then(m => m.default || m),
    children: [
  {
    name: _91cinemaSlug_93YePGbqbiCHMeta?.name,
    path: "/kino/:citySlug/:cinemaSlug",
    component: () => import("/home/forge/deployments/kinoheld/dev/2024-11-13_11-09-55_7e8ca1b/layers/portal/pages/cinema/[citySlug]/[cinemaSlug].vue").then(m => m.default || m),
    children: [
  {
    name: "cinema-citySlug-cinemaSlug-faq___de",
    path: "faq",
    component: () => import("/home/forge/deployments/kinoheld/dev/2024-11-13_11-09-55_7e8ca1b/layers/portal/pages/cinema/[citySlug]/[cinemaSlug]/faq.vue").then(m => m.default || m)
  },
  {
    name: "cinema-citySlug-cinemaSlug___de",
    path: "",
    meta: indexFWCVYAYQbPMeta || {},
    component: () => import("/home/forge/deployments/kinoheld/dev/2024-11-13_11-09-55_7e8ca1b/layers/portal/pages/cinema/[citySlug]/[cinemaSlug]/index.vue").then(m => m.default || m)
  },
  {
    name: "cinema-citySlug-cinemaSlug-info___de",
    path: "/kino/:citySlug/:cinemaSlug/info",
    component: () => import("/home/forge/deployments/kinoheld/dev/2024-11-13_11-09-55_7e8ca1b/layers/portal/pages/cinema/[citySlug]/[cinemaSlug]/info.vue").then(m => m.default || m)
  },
  {
    name: "cinema-citySlug-cinemaSlug-movie-showGroupSlug___de",
    path: "/kino/:citySlug/:cinemaSlug/film/:showGroupSlug",
    component: () => import("/home/forge/deployments/kinoheld/dev/2024-11-13_11-09-55_7e8ca1b/layers/portal/pages/cinema/[citySlug]/[cinemaSlug]/movie/[showGroupSlug].vue").then(m => m.default || m)
  },
  {
    name: "cinema-citySlug-cinemaSlug-program___de",
    path: "/kino/:citySlug/:cinemaSlug/vorstellungen",
    component: () => import("/home/forge/deployments/kinoheld/dev/2024-11-13_11-09-55_7e8ca1b/layers/portal/pages/cinema/[citySlug]/[cinemaSlug]/program.vue").then(m => m.default || m)
  },
  {
    name: "cinema-citySlug-cinemaSlug-shop___de",
    path: "shop",
    component: () => import("/home/forge/deployments/kinoheld/dev/2024-11-13_11-09-55_7e8ca1b/layers/portal/pages/cinema/[citySlug]/[cinemaSlug]/shop.vue").then(m => m.default || m)
  },
  {
    name: "cinema-citySlug-cinemaSlug-show-showSlug___de",
    path: "/kino/:citySlug/:cinemaSlug/vorstellung/:showSlug",
    component: () => import("/home/forge/deployments/kinoheld/dev/2024-11-13_11-09-55_7e8ca1b/layers/portal/pages/cinema/[citySlug]/[cinemaSlug]/show/[showSlug].vue").then(m => m.default || m),
    children: [
  {
    name: "cinema-citySlug-cinemaSlug-show-showSlug-showName___de",
    path: "/kino/:citySlug/:cinemaSlug/vorstellung/:showSlug/:showName",
    component: () => import("/home/forge/deployments/kinoheld/dev/2024-11-13_11-09-55_7e8ca1b/layers/portal/pages/cinema/[citySlug]/[cinemaSlug]/show/[showSlug]/[showName].vue").then(m => m.default || m)
  }
]
  },
  {
    name: "cinema-citySlug-cinemaSlug-vouchers___de",
    path: "/kino/:citySlug/:cinemaSlug/gutscheine",
    component: () => import("/home/forge/deployments/kinoheld/dev/2024-11-13_11-09-55_7e8ca1b/layers/portal/pages/cinema/[citySlug]/[cinemaSlug]/vouchers.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "cinema-citySlug___de",
    path: "",
    component: () => import("/home/forge/deployments/kinoheld/dev/2024-11-13_11-09-55_7e8ca1b/layers/portal/pages/cinema/[citySlug]/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: _91citySlug_93El5xNHuK5bMeta?.name,
    path: "/es/cine/:citySlug",
    component: () => import("/home/forge/deployments/kinoheld/dev/2024-11-13_11-09-55_7e8ca1b/layers/portal/pages/cinema/[citySlug].vue").then(m => m.default || m),
    children: [
  {
    name: _91cinemaSlug_93YePGbqbiCHMeta?.name,
    path: "/es/cine/:citySlug/:cinemaSlug",
    component: () => import("/home/forge/deployments/kinoheld/dev/2024-11-13_11-09-55_7e8ca1b/layers/portal/pages/cinema/[citySlug]/[cinemaSlug].vue").then(m => m.default || m),
    children: [
  {
    name: "cinema-citySlug-cinemaSlug-faq___es",
    path: "faq",
    component: () => import("/home/forge/deployments/kinoheld/dev/2024-11-13_11-09-55_7e8ca1b/layers/portal/pages/cinema/[citySlug]/[cinemaSlug]/faq.vue").then(m => m.default || m)
  },
  {
    name: "cinema-citySlug-cinemaSlug___es",
    path: "",
    meta: indexFWCVYAYQbPMeta || {},
    component: () => import("/home/forge/deployments/kinoheld/dev/2024-11-13_11-09-55_7e8ca1b/layers/portal/pages/cinema/[citySlug]/[cinemaSlug]/index.vue").then(m => m.default || m)
  },
  {
    name: "cinema-citySlug-cinemaSlug-info___es",
    path: "/es/cine/:citySlug/:cinemaSlug/informacion",
    component: () => import("/home/forge/deployments/kinoheld/dev/2024-11-13_11-09-55_7e8ca1b/layers/portal/pages/cinema/[citySlug]/[cinemaSlug]/info.vue").then(m => m.default || m)
  },
  {
    name: "cinema-citySlug-cinemaSlug-movie-showGroupSlug___es",
    path: "movie/:showGroupSlug()",
    component: () => import("/home/forge/deployments/kinoheld/dev/2024-11-13_11-09-55_7e8ca1b/layers/portal/pages/cinema/[citySlug]/[cinemaSlug]/movie/[showGroupSlug].vue").then(m => m.default || m)
  },
  {
    name: "cinema-citySlug-cinemaSlug-program___es",
    path: "/es/cine/:citySlug/:cinemaSlug/sesiones",
    component: () => import("/home/forge/deployments/kinoheld/dev/2024-11-13_11-09-55_7e8ca1b/layers/portal/pages/cinema/[citySlug]/[cinemaSlug]/program.vue").then(m => m.default || m)
  },
  {
    name: "cinema-citySlug-cinemaSlug-shop___es",
    path: "shop",
    component: () => import("/home/forge/deployments/kinoheld/dev/2024-11-13_11-09-55_7e8ca1b/layers/portal/pages/cinema/[citySlug]/[cinemaSlug]/shop.vue").then(m => m.default || m)
  },
  {
    name: "cinema-citySlug-cinemaSlug-show-showSlug___es",
    path: "/es/cine/:citySlug/:cinemaSlug/evento/:showSlug",
    component: () => import("/home/forge/deployments/kinoheld/dev/2024-11-13_11-09-55_7e8ca1b/layers/portal/pages/cinema/[citySlug]/[cinemaSlug]/show/[showSlug].vue").then(m => m.default || m),
    children: [
  {
    name: "cinema-citySlug-cinemaSlug-show-showSlug-showName___es",
    path: "/es/cine/:citySlug/:cinemaSlug/evento/:showSlug/:showName",
    component: () => import("/home/forge/deployments/kinoheld/dev/2024-11-13_11-09-55_7e8ca1b/layers/portal/pages/cinema/[citySlug]/[cinemaSlug]/show/[showSlug]/[showName].vue").then(m => m.default || m)
  }
]
  },
  {
    name: "cinema-citySlug-cinemaSlug-vouchers___es",
    path: "/es/cine/:citySlug/:cinemaSlug/codigos",
    component: () => import("/home/forge/deployments/kinoheld/dev/2024-11-13_11-09-55_7e8ca1b/layers/portal/pages/cinema/[citySlug]/[cinemaSlug]/vouchers.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "cinema-citySlug___es",
    path: "",
    component: () => import("/home/forge/deployments/kinoheld/dev/2024-11-13_11-09-55_7e8ca1b/layers/portal/pages/cinema/[citySlug]/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: _91citySlug_93El5xNHuK5bMeta?.name,
    path: "/kino/:citySlug",
    component: () => import("/home/forge/deployments/kinoheld/dev/2024-11-13_11-09-55_7e8ca1b/layers/portal/pages/cinema/[citySlug].vue").then(m => m.default || m),
    children: [
  {
    name: _91cinemaSlug_93YePGbqbiCHMeta?.name,
    path: "/kino/:citySlug/:cinemaSlug",
    component: () => import("/home/forge/deployments/kinoheld/dev/2024-11-13_11-09-55_7e8ca1b/layers/portal/pages/cinema/[citySlug]/[cinemaSlug].vue").then(m => m.default || m),
    children: [
  {
    name: "cinema-citySlug-cinemaSlug-faq___de",
    path: "faq",
    component: () => import("/home/forge/deployments/kinoheld/dev/2024-11-13_11-09-55_7e8ca1b/layers/portal/pages/cinema/[citySlug]/[cinemaSlug]/faq.vue").then(m => m.default || m)
  },
  {
    name: "cinema-citySlug-cinemaSlug___de",
    path: "",
    meta: indexFWCVYAYQbPMeta || {},
    component: () => import("/home/forge/deployments/kinoheld/dev/2024-11-13_11-09-55_7e8ca1b/layers/portal/pages/cinema/[citySlug]/[cinemaSlug]/index.vue").then(m => m.default || m)
  },
  {
    name: "cinema-citySlug-cinemaSlug-info___de",
    path: "/kino/:citySlug/:cinemaSlug/info",
    component: () => import("/home/forge/deployments/kinoheld/dev/2024-11-13_11-09-55_7e8ca1b/layers/portal/pages/cinema/[citySlug]/[cinemaSlug]/info.vue").then(m => m.default || m)
  },
  {
    name: "cinema-citySlug-cinemaSlug-movie-showGroupSlug___de",
    path: "/kino/:citySlug/:cinemaSlug/film/:showGroupSlug",
    component: () => import("/home/forge/deployments/kinoheld/dev/2024-11-13_11-09-55_7e8ca1b/layers/portal/pages/cinema/[citySlug]/[cinemaSlug]/movie/[showGroupSlug].vue").then(m => m.default || m)
  },
  {
    name: "cinema-citySlug-cinemaSlug-program___de",
    path: "/kino/:citySlug/:cinemaSlug/vorstellungen",
    component: () => import("/home/forge/deployments/kinoheld/dev/2024-11-13_11-09-55_7e8ca1b/layers/portal/pages/cinema/[citySlug]/[cinemaSlug]/program.vue").then(m => m.default || m)
  },
  {
    name: "cinema-citySlug-cinemaSlug-shop___de",
    path: "shop",
    component: () => import("/home/forge/deployments/kinoheld/dev/2024-11-13_11-09-55_7e8ca1b/layers/portal/pages/cinema/[citySlug]/[cinemaSlug]/shop.vue").then(m => m.default || m)
  },
  {
    name: "cinema-citySlug-cinemaSlug-show-showSlug___de",
    path: "/kino/:citySlug/:cinemaSlug/vorstellung/:showSlug",
    component: () => import("/home/forge/deployments/kinoheld/dev/2024-11-13_11-09-55_7e8ca1b/layers/portal/pages/cinema/[citySlug]/[cinemaSlug]/show/[showSlug].vue").then(m => m.default || m),
    children: [
  {
    name: "cinema-citySlug-cinemaSlug-show-showSlug-showName___de",
    path: "/kino/:citySlug/:cinemaSlug/vorstellung/:showSlug/:showName",
    component: () => import("/home/forge/deployments/kinoheld/dev/2024-11-13_11-09-55_7e8ca1b/layers/portal/pages/cinema/[citySlug]/[cinemaSlug]/show/[showSlug]/[showName].vue").then(m => m.default || m)
  }
]
  },
  {
    name: "cinema-citySlug-cinemaSlug-vouchers___de",
    path: "/kino/:citySlug/:cinemaSlug/gutscheine",
    component: () => import("/home/forge/deployments/kinoheld/dev/2024-11-13_11-09-55_7e8ca1b/layers/portal/pages/cinema/[citySlug]/[cinemaSlug]/vouchers.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "cinema-citySlug___de",
    path: "",
    component: () => import("/home/forge/deployments/kinoheld/dev/2024-11-13_11-09-55_7e8ca1b/layers/portal/pages/cinema/[citySlug]/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: _91citySlug_93El5xNHuK5bMeta?.name,
    path: "/es/cine/:citySlug",
    component: () => import("/home/forge/deployments/kinoheld/dev/2024-11-13_11-09-55_7e8ca1b/layers/portal/pages/cinema/[citySlug].vue").then(m => m.default || m),
    children: [
  {
    name: _91cinemaSlug_93YePGbqbiCHMeta?.name,
    path: "/es/cine/:citySlug/:cinemaSlug",
    component: () => import("/home/forge/deployments/kinoheld/dev/2024-11-13_11-09-55_7e8ca1b/layers/portal/pages/cinema/[citySlug]/[cinemaSlug].vue").then(m => m.default || m),
    children: [
  {
    name: "cinema-citySlug-cinemaSlug-faq___es",
    path: "faq",
    component: () => import("/home/forge/deployments/kinoheld/dev/2024-11-13_11-09-55_7e8ca1b/layers/portal/pages/cinema/[citySlug]/[cinemaSlug]/faq.vue").then(m => m.default || m)
  },
  {
    name: "cinema-citySlug-cinemaSlug___es",
    path: "",
    meta: indexFWCVYAYQbPMeta || {},
    component: () => import("/home/forge/deployments/kinoheld/dev/2024-11-13_11-09-55_7e8ca1b/layers/portal/pages/cinema/[citySlug]/[cinemaSlug]/index.vue").then(m => m.default || m)
  },
  {
    name: "cinema-citySlug-cinemaSlug-info___es",
    path: "/es/cine/:citySlug/:cinemaSlug/informacion",
    component: () => import("/home/forge/deployments/kinoheld/dev/2024-11-13_11-09-55_7e8ca1b/layers/portal/pages/cinema/[citySlug]/[cinemaSlug]/info.vue").then(m => m.default || m)
  },
  {
    name: "cinema-citySlug-cinemaSlug-movie-showGroupSlug___es",
    path: "movie/:showGroupSlug()",
    component: () => import("/home/forge/deployments/kinoheld/dev/2024-11-13_11-09-55_7e8ca1b/layers/portal/pages/cinema/[citySlug]/[cinemaSlug]/movie/[showGroupSlug].vue").then(m => m.default || m)
  },
  {
    name: "cinema-citySlug-cinemaSlug-program___es",
    path: "/es/cine/:citySlug/:cinemaSlug/sesiones",
    component: () => import("/home/forge/deployments/kinoheld/dev/2024-11-13_11-09-55_7e8ca1b/layers/portal/pages/cinema/[citySlug]/[cinemaSlug]/program.vue").then(m => m.default || m)
  },
  {
    name: "cinema-citySlug-cinemaSlug-shop___es",
    path: "shop",
    component: () => import("/home/forge/deployments/kinoheld/dev/2024-11-13_11-09-55_7e8ca1b/layers/portal/pages/cinema/[citySlug]/[cinemaSlug]/shop.vue").then(m => m.default || m)
  },
  {
    name: "cinema-citySlug-cinemaSlug-show-showSlug___es",
    path: "/es/cine/:citySlug/:cinemaSlug/evento/:showSlug",
    component: () => import("/home/forge/deployments/kinoheld/dev/2024-11-13_11-09-55_7e8ca1b/layers/portal/pages/cinema/[citySlug]/[cinemaSlug]/show/[showSlug].vue").then(m => m.default || m),
    children: [
  {
    name: "cinema-citySlug-cinemaSlug-show-showSlug-showName___es",
    path: "/es/cine/:citySlug/:cinemaSlug/evento/:showSlug/:showName",
    component: () => import("/home/forge/deployments/kinoheld/dev/2024-11-13_11-09-55_7e8ca1b/layers/portal/pages/cinema/[citySlug]/[cinemaSlug]/show/[showSlug]/[showName].vue").then(m => m.default || m)
  }
]
  },
  {
    name: "cinema-citySlug-cinemaSlug-vouchers___es",
    path: "/es/cine/:citySlug/:cinemaSlug/codigos",
    component: () => import("/home/forge/deployments/kinoheld/dev/2024-11-13_11-09-55_7e8ca1b/layers/portal/pages/cinema/[citySlug]/[cinemaSlug]/vouchers.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "cinema-citySlug___es",
    path: "",
    component: () => import("/home/forge/deployments/kinoheld/dev/2024-11-13_11-09-55_7e8ca1b/layers/portal/pages/cinema/[citySlug]/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: _91citySlug_93El5xNHuK5bMeta?.name,
    path: "/kino/:citySlug",
    component: () => import("/home/forge/deployments/kinoheld/dev/2024-11-13_11-09-55_7e8ca1b/layers/portal/pages/cinema/[citySlug].vue").then(m => m.default || m),
    children: [
  {
    name: _91cinemaSlug_93YePGbqbiCHMeta?.name,
    path: "/kino/:citySlug/:cinemaSlug",
    component: () => import("/home/forge/deployments/kinoheld/dev/2024-11-13_11-09-55_7e8ca1b/layers/portal/pages/cinema/[citySlug]/[cinemaSlug].vue").then(m => m.default || m),
    children: [
  {
    name: "cinema-citySlug-cinemaSlug-faq___de",
    path: "faq",
    component: () => import("/home/forge/deployments/kinoheld/dev/2024-11-13_11-09-55_7e8ca1b/layers/portal/pages/cinema/[citySlug]/[cinemaSlug]/faq.vue").then(m => m.default || m)
  },
  {
    name: "cinema-citySlug-cinemaSlug___de",
    path: "",
    meta: indexFWCVYAYQbPMeta || {},
    component: () => import("/home/forge/deployments/kinoheld/dev/2024-11-13_11-09-55_7e8ca1b/layers/portal/pages/cinema/[citySlug]/[cinemaSlug]/index.vue").then(m => m.default || m)
  },
  {
    name: "cinema-citySlug-cinemaSlug-info___de",
    path: "/kino/:citySlug/:cinemaSlug/info",
    component: () => import("/home/forge/deployments/kinoheld/dev/2024-11-13_11-09-55_7e8ca1b/layers/portal/pages/cinema/[citySlug]/[cinemaSlug]/info.vue").then(m => m.default || m)
  },
  {
    name: "cinema-citySlug-cinemaSlug-movie-showGroupSlug___de",
    path: "/kino/:citySlug/:cinemaSlug/film/:showGroupSlug",
    component: () => import("/home/forge/deployments/kinoheld/dev/2024-11-13_11-09-55_7e8ca1b/layers/portal/pages/cinema/[citySlug]/[cinemaSlug]/movie/[showGroupSlug].vue").then(m => m.default || m)
  },
  {
    name: "cinema-citySlug-cinemaSlug-program___de",
    path: "/kino/:citySlug/:cinemaSlug/vorstellungen",
    component: () => import("/home/forge/deployments/kinoheld/dev/2024-11-13_11-09-55_7e8ca1b/layers/portal/pages/cinema/[citySlug]/[cinemaSlug]/program.vue").then(m => m.default || m)
  },
  {
    name: "cinema-citySlug-cinemaSlug-shop___de",
    path: "shop",
    component: () => import("/home/forge/deployments/kinoheld/dev/2024-11-13_11-09-55_7e8ca1b/layers/portal/pages/cinema/[citySlug]/[cinemaSlug]/shop.vue").then(m => m.default || m)
  },
  {
    name: "cinema-citySlug-cinemaSlug-show-showSlug___de",
    path: "/kino/:citySlug/:cinemaSlug/vorstellung/:showSlug",
    component: () => import("/home/forge/deployments/kinoheld/dev/2024-11-13_11-09-55_7e8ca1b/layers/portal/pages/cinema/[citySlug]/[cinemaSlug]/show/[showSlug].vue").then(m => m.default || m),
    children: [
  {
    name: "cinema-citySlug-cinemaSlug-show-showSlug-showName___de",
    path: "/kino/:citySlug/:cinemaSlug/vorstellung/:showSlug/:showName",
    component: () => import("/home/forge/deployments/kinoheld/dev/2024-11-13_11-09-55_7e8ca1b/layers/portal/pages/cinema/[citySlug]/[cinemaSlug]/show/[showSlug]/[showName].vue").then(m => m.default || m)
  }
]
  },
  {
    name: "cinema-citySlug-cinemaSlug-vouchers___de",
    path: "/kino/:citySlug/:cinemaSlug/gutscheine",
    component: () => import("/home/forge/deployments/kinoheld/dev/2024-11-13_11-09-55_7e8ca1b/layers/portal/pages/cinema/[citySlug]/[cinemaSlug]/vouchers.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "cinema-citySlug___de",
    path: "",
    component: () => import("/home/forge/deployments/kinoheld/dev/2024-11-13_11-09-55_7e8ca1b/layers/portal/pages/cinema/[citySlug]/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: _91citySlug_93El5xNHuK5bMeta?.name,
    path: "/es/cine/:citySlug",
    component: () => import("/home/forge/deployments/kinoheld/dev/2024-11-13_11-09-55_7e8ca1b/layers/portal/pages/cinema/[citySlug].vue").then(m => m.default || m),
    children: [
  {
    name: _91cinemaSlug_93YePGbqbiCHMeta?.name,
    path: "/es/cine/:citySlug/:cinemaSlug",
    component: () => import("/home/forge/deployments/kinoheld/dev/2024-11-13_11-09-55_7e8ca1b/layers/portal/pages/cinema/[citySlug]/[cinemaSlug].vue").then(m => m.default || m),
    children: [
  {
    name: "cinema-citySlug-cinemaSlug-faq___es",
    path: "faq",
    component: () => import("/home/forge/deployments/kinoheld/dev/2024-11-13_11-09-55_7e8ca1b/layers/portal/pages/cinema/[citySlug]/[cinemaSlug]/faq.vue").then(m => m.default || m)
  },
  {
    name: "cinema-citySlug-cinemaSlug___es",
    path: "",
    meta: indexFWCVYAYQbPMeta || {},
    component: () => import("/home/forge/deployments/kinoheld/dev/2024-11-13_11-09-55_7e8ca1b/layers/portal/pages/cinema/[citySlug]/[cinemaSlug]/index.vue").then(m => m.default || m)
  },
  {
    name: "cinema-citySlug-cinemaSlug-info___es",
    path: "/es/cine/:citySlug/:cinemaSlug/informacion",
    component: () => import("/home/forge/deployments/kinoheld/dev/2024-11-13_11-09-55_7e8ca1b/layers/portal/pages/cinema/[citySlug]/[cinemaSlug]/info.vue").then(m => m.default || m)
  },
  {
    name: "cinema-citySlug-cinemaSlug-movie-showGroupSlug___es",
    path: "movie/:showGroupSlug()",
    component: () => import("/home/forge/deployments/kinoheld/dev/2024-11-13_11-09-55_7e8ca1b/layers/portal/pages/cinema/[citySlug]/[cinemaSlug]/movie/[showGroupSlug].vue").then(m => m.default || m)
  },
  {
    name: "cinema-citySlug-cinemaSlug-program___es",
    path: "/es/cine/:citySlug/:cinemaSlug/sesiones",
    component: () => import("/home/forge/deployments/kinoheld/dev/2024-11-13_11-09-55_7e8ca1b/layers/portal/pages/cinema/[citySlug]/[cinemaSlug]/program.vue").then(m => m.default || m)
  },
  {
    name: "cinema-citySlug-cinemaSlug-shop___es",
    path: "shop",
    component: () => import("/home/forge/deployments/kinoheld/dev/2024-11-13_11-09-55_7e8ca1b/layers/portal/pages/cinema/[citySlug]/[cinemaSlug]/shop.vue").then(m => m.default || m)
  },
  {
    name: "cinema-citySlug-cinemaSlug-show-showSlug___es",
    path: "/es/cine/:citySlug/:cinemaSlug/evento/:showSlug",
    component: () => import("/home/forge/deployments/kinoheld/dev/2024-11-13_11-09-55_7e8ca1b/layers/portal/pages/cinema/[citySlug]/[cinemaSlug]/show/[showSlug].vue").then(m => m.default || m),
    children: [
  {
    name: "cinema-citySlug-cinemaSlug-show-showSlug-showName___es",
    path: "/es/cine/:citySlug/:cinemaSlug/evento/:showSlug/:showName",
    component: () => import("/home/forge/deployments/kinoheld/dev/2024-11-13_11-09-55_7e8ca1b/layers/portal/pages/cinema/[citySlug]/[cinemaSlug]/show/[showSlug]/[showName].vue").then(m => m.default || m)
  }
]
  },
  {
    name: "cinema-citySlug-cinemaSlug-vouchers___es",
    path: "/es/cine/:citySlug/:cinemaSlug/codigos",
    component: () => import("/home/forge/deployments/kinoheld/dev/2024-11-13_11-09-55_7e8ca1b/layers/portal/pages/cinema/[citySlug]/[cinemaSlug]/vouchers.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "cinema-citySlug___es",
    path: "",
    component: () => import("/home/forge/deployments/kinoheld/dev/2024-11-13_11-09-55_7e8ca1b/layers/portal/pages/cinema/[citySlug]/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: _91citySlug_93El5xNHuK5bMeta?.name,
    path: "/kino/:citySlug",
    component: () => import("/home/forge/deployments/kinoheld/dev/2024-11-13_11-09-55_7e8ca1b/layers/portal/pages/cinema/[citySlug].vue").then(m => m.default || m),
    children: [
  {
    name: _91cinemaSlug_93YePGbqbiCHMeta?.name,
    path: "/kino/:citySlug/:cinemaSlug",
    component: () => import("/home/forge/deployments/kinoheld/dev/2024-11-13_11-09-55_7e8ca1b/layers/portal/pages/cinema/[citySlug]/[cinemaSlug].vue").then(m => m.default || m),
    children: [
  {
    name: "cinema-citySlug-cinemaSlug-faq___de",
    path: "faq",
    component: () => import("/home/forge/deployments/kinoheld/dev/2024-11-13_11-09-55_7e8ca1b/layers/portal/pages/cinema/[citySlug]/[cinemaSlug]/faq.vue").then(m => m.default || m)
  },
  {
    name: "cinema-citySlug-cinemaSlug___de",
    path: "",
    meta: indexFWCVYAYQbPMeta || {},
    component: () => import("/home/forge/deployments/kinoheld/dev/2024-11-13_11-09-55_7e8ca1b/layers/portal/pages/cinema/[citySlug]/[cinemaSlug]/index.vue").then(m => m.default || m)
  },
  {
    name: "cinema-citySlug-cinemaSlug-info___de",
    path: "/kino/:citySlug/:cinemaSlug/info",
    component: () => import("/home/forge/deployments/kinoheld/dev/2024-11-13_11-09-55_7e8ca1b/layers/portal/pages/cinema/[citySlug]/[cinemaSlug]/info.vue").then(m => m.default || m)
  },
  {
    name: "cinema-citySlug-cinemaSlug-movie-showGroupSlug___de",
    path: "/kino/:citySlug/:cinemaSlug/film/:showGroupSlug",
    component: () => import("/home/forge/deployments/kinoheld/dev/2024-11-13_11-09-55_7e8ca1b/layers/portal/pages/cinema/[citySlug]/[cinemaSlug]/movie/[showGroupSlug].vue").then(m => m.default || m)
  },
  {
    name: "cinema-citySlug-cinemaSlug-program___de",
    path: "/kino/:citySlug/:cinemaSlug/vorstellungen",
    component: () => import("/home/forge/deployments/kinoheld/dev/2024-11-13_11-09-55_7e8ca1b/layers/portal/pages/cinema/[citySlug]/[cinemaSlug]/program.vue").then(m => m.default || m)
  },
  {
    name: "cinema-citySlug-cinemaSlug-shop___de",
    path: "shop",
    component: () => import("/home/forge/deployments/kinoheld/dev/2024-11-13_11-09-55_7e8ca1b/layers/portal/pages/cinema/[citySlug]/[cinemaSlug]/shop.vue").then(m => m.default || m)
  },
  {
    name: "cinema-citySlug-cinemaSlug-show-showSlug___de",
    path: "/kino/:citySlug/:cinemaSlug/vorstellung/:showSlug",
    component: () => import("/home/forge/deployments/kinoheld/dev/2024-11-13_11-09-55_7e8ca1b/layers/portal/pages/cinema/[citySlug]/[cinemaSlug]/show/[showSlug].vue").then(m => m.default || m),
    children: [
  {
    name: "cinema-citySlug-cinemaSlug-show-showSlug-showName___de",
    path: "/kino/:citySlug/:cinemaSlug/vorstellung/:showSlug/:showName",
    component: () => import("/home/forge/deployments/kinoheld/dev/2024-11-13_11-09-55_7e8ca1b/layers/portal/pages/cinema/[citySlug]/[cinemaSlug]/show/[showSlug]/[showName].vue").then(m => m.default || m)
  }
]
  },
  {
    name: "cinema-citySlug-cinemaSlug-vouchers___de",
    path: "/kino/:citySlug/:cinemaSlug/gutscheine",
    component: () => import("/home/forge/deployments/kinoheld/dev/2024-11-13_11-09-55_7e8ca1b/layers/portal/pages/cinema/[citySlug]/[cinemaSlug]/vouchers.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "cinema-citySlug___de",
    path: "",
    component: () => import("/home/forge/deployments/kinoheld/dev/2024-11-13_11-09-55_7e8ca1b/layers/portal/pages/cinema/[citySlug]/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: _91citySlug_93El5xNHuK5bMeta?.name,
    path: "/es/cine/:citySlug",
    component: () => import("/home/forge/deployments/kinoheld/dev/2024-11-13_11-09-55_7e8ca1b/layers/portal/pages/cinema/[citySlug].vue").then(m => m.default || m),
    children: [
  {
    name: _91cinemaSlug_93YePGbqbiCHMeta?.name,
    path: "/es/cine/:citySlug/:cinemaSlug",
    component: () => import("/home/forge/deployments/kinoheld/dev/2024-11-13_11-09-55_7e8ca1b/layers/portal/pages/cinema/[citySlug]/[cinemaSlug].vue").then(m => m.default || m),
    children: [
  {
    name: "cinema-citySlug-cinemaSlug-faq___es",
    path: "faq",
    component: () => import("/home/forge/deployments/kinoheld/dev/2024-11-13_11-09-55_7e8ca1b/layers/portal/pages/cinema/[citySlug]/[cinemaSlug]/faq.vue").then(m => m.default || m)
  },
  {
    name: "cinema-citySlug-cinemaSlug___es",
    path: "",
    meta: indexFWCVYAYQbPMeta || {},
    component: () => import("/home/forge/deployments/kinoheld/dev/2024-11-13_11-09-55_7e8ca1b/layers/portal/pages/cinema/[citySlug]/[cinemaSlug]/index.vue").then(m => m.default || m)
  },
  {
    name: "cinema-citySlug-cinemaSlug-info___es",
    path: "/es/cine/:citySlug/:cinemaSlug/informacion",
    component: () => import("/home/forge/deployments/kinoheld/dev/2024-11-13_11-09-55_7e8ca1b/layers/portal/pages/cinema/[citySlug]/[cinemaSlug]/info.vue").then(m => m.default || m)
  },
  {
    name: "cinema-citySlug-cinemaSlug-movie-showGroupSlug___es",
    path: "movie/:showGroupSlug()",
    component: () => import("/home/forge/deployments/kinoheld/dev/2024-11-13_11-09-55_7e8ca1b/layers/portal/pages/cinema/[citySlug]/[cinemaSlug]/movie/[showGroupSlug].vue").then(m => m.default || m)
  },
  {
    name: "cinema-citySlug-cinemaSlug-program___es",
    path: "/es/cine/:citySlug/:cinemaSlug/sesiones",
    component: () => import("/home/forge/deployments/kinoheld/dev/2024-11-13_11-09-55_7e8ca1b/layers/portal/pages/cinema/[citySlug]/[cinemaSlug]/program.vue").then(m => m.default || m)
  },
  {
    name: "cinema-citySlug-cinemaSlug-shop___es",
    path: "shop",
    component: () => import("/home/forge/deployments/kinoheld/dev/2024-11-13_11-09-55_7e8ca1b/layers/portal/pages/cinema/[citySlug]/[cinemaSlug]/shop.vue").then(m => m.default || m)
  },
  {
    name: "cinema-citySlug-cinemaSlug-show-showSlug___es",
    path: "/es/cine/:citySlug/:cinemaSlug/evento/:showSlug",
    component: () => import("/home/forge/deployments/kinoheld/dev/2024-11-13_11-09-55_7e8ca1b/layers/portal/pages/cinema/[citySlug]/[cinemaSlug]/show/[showSlug].vue").then(m => m.default || m),
    children: [
  {
    name: "cinema-citySlug-cinemaSlug-show-showSlug-showName___es",
    path: "/es/cine/:citySlug/:cinemaSlug/evento/:showSlug/:showName",
    component: () => import("/home/forge/deployments/kinoheld/dev/2024-11-13_11-09-55_7e8ca1b/layers/portal/pages/cinema/[citySlug]/[cinemaSlug]/show/[showSlug]/[showName].vue").then(m => m.default || m)
  }
]
  },
  {
    name: "cinema-citySlug-cinemaSlug-vouchers___es",
    path: "/es/cine/:citySlug/:cinemaSlug/codigos",
    component: () => import("/home/forge/deployments/kinoheld/dev/2024-11-13_11-09-55_7e8ca1b/layers/portal/pages/cinema/[citySlug]/[cinemaSlug]/vouchers.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "cinema-citySlug___es",
    path: "",
    component: () => import("/home/forge/deployments/kinoheld/dev/2024-11-13_11-09-55_7e8ca1b/layers/portal/pages/cinema/[citySlug]/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: _91citySlug_93El5xNHuK5bMeta?.name,
    path: "/kino/:citySlug",
    component: () => import("/home/forge/deployments/kinoheld/dev/2024-11-13_11-09-55_7e8ca1b/layers/portal/pages/cinema/[citySlug].vue").then(m => m.default || m),
    children: [
  {
    name: _91cinemaSlug_93YePGbqbiCHMeta?.name,
    path: "/kino/:citySlug/:cinemaSlug",
    component: () => import("/home/forge/deployments/kinoheld/dev/2024-11-13_11-09-55_7e8ca1b/layers/portal/pages/cinema/[citySlug]/[cinemaSlug].vue").then(m => m.default || m),
    children: [
  {
    name: "cinema-citySlug-cinemaSlug-faq___de",
    path: "faq",
    component: () => import("/home/forge/deployments/kinoheld/dev/2024-11-13_11-09-55_7e8ca1b/layers/portal/pages/cinema/[citySlug]/[cinemaSlug]/faq.vue").then(m => m.default || m)
  },
  {
    name: "cinema-citySlug-cinemaSlug___de",
    path: "",
    meta: indexFWCVYAYQbPMeta || {},
    component: () => import("/home/forge/deployments/kinoheld/dev/2024-11-13_11-09-55_7e8ca1b/layers/portal/pages/cinema/[citySlug]/[cinemaSlug]/index.vue").then(m => m.default || m)
  },
  {
    name: "cinema-citySlug-cinemaSlug-info___de",
    path: "/kino/:citySlug/:cinemaSlug/info",
    component: () => import("/home/forge/deployments/kinoheld/dev/2024-11-13_11-09-55_7e8ca1b/layers/portal/pages/cinema/[citySlug]/[cinemaSlug]/info.vue").then(m => m.default || m)
  },
  {
    name: "cinema-citySlug-cinemaSlug-movie-showGroupSlug___de",
    path: "/kino/:citySlug/:cinemaSlug/film/:showGroupSlug",
    component: () => import("/home/forge/deployments/kinoheld/dev/2024-11-13_11-09-55_7e8ca1b/layers/portal/pages/cinema/[citySlug]/[cinemaSlug]/movie/[showGroupSlug].vue").then(m => m.default || m)
  },
  {
    name: "cinema-citySlug-cinemaSlug-program___de",
    path: "/kino/:citySlug/:cinemaSlug/vorstellungen",
    component: () => import("/home/forge/deployments/kinoheld/dev/2024-11-13_11-09-55_7e8ca1b/layers/portal/pages/cinema/[citySlug]/[cinemaSlug]/program.vue").then(m => m.default || m)
  },
  {
    name: "cinema-citySlug-cinemaSlug-shop___de",
    path: "shop",
    component: () => import("/home/forge/deployments/kinoheld/dev/2024-11-13_11-09-55_7e8ca1b/layers/portal/pages/cinema/[citySlug]/[cinemaSlug]/shop.vue").then(m => m.default || m)
  },
  {
    name: "cinema-citySlug-cinemaSlug-show-showSlug___de",
    path: "/kino/:citySlug/:cinemaSlug/vorstellung/:showSlug",
    component: () => import("/home/forge/deployments/kinoheld/dev/2024-11-13_11-09-55_7e8ca1b/layers/portal/pages/cinema/[citySlug]/[cinemaSlug]/show/[showSlug].vue").then(m => m.default || m),
    children: [
  {
    name: "cinema-citySlug-cinemaSlug-show-showSlug-showName___de",
    path: "/kino/:citySlug/:cinemaSlug/vorstellung/:showSlug/:showName",
    component: () => import("/home/forge/deployments/kinoheld/dev/2024-11-13_11-09-55_7e8ca1b/layers/portal/pages/cinema/[citySlug]/[cinemaSlug]/show/[showSlug]/[showName].vue").then(m => m.default || m)
  }
]
  },
  {
    name: "cinema-citySlug-cinemaSlug-vouchers___de",
    path: "/kino/:citySlug/:cinemaSlug/gutscheine",
    component: () => import("/home/forge/deployments/kinoheld/dev/2024-11-13_11-09-55_7e8ca1b/layers/portal/pages/cinema/[citySlug]/[cinemaSlug]/vouchers.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "cinema-citySlug___de",
    path: "",
    component: () => import("/home/forge/deployments/kinoheld/dev/2024-11-13_11-09-55_7e8ca1b/layers/portal/pages/cinema/[citySlug]/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: _91citySlug_93El5xNHuK5bMeta?.name,
    path: "/es/cine/:citySlug",
    component: () => import("/home/forge/deployments/kinoheld/dev/2024-11-13_11-09-55_7e8ca1b/layers/portal/pages/cinema/[citySlug].vue").then(m => m.default || m),
    children: [
  {
    name: _91cinemaSlug_93YePGbqbiCHMeta?.name,
    path: "/es/cine/:citySlug/:cinemaSlug",
    component: () => import("/home/forge/deployments/kinoheld/dev/2024-11-13_11-09-55_7e8ca1b/layers/portal/pages/cinema/[citySlug]/[cinemaSlug].vue").then(m => m.default || m),
    children: [
  {
    name: "cinema-citySlug-cinemaSlug-faq___es",
    path: "faq",
    component: () => import("/home/forge/deployments/kinoheld/dev/2024-11-13_11-09-55_7e8ca1b/layers/portal/pages/cinema/[citySlug]/[cinemaSlug]/faq.vue").then(m => m.default || m)
  },
  {
    name: "cinema-citySlug-cinemaSlug___es",
    path: "",
    meta: indexFWCVYAYQbPMeta || {},
    component: () => import("/home/forge/deployments/kinoheld/dev/2024-11-13_11-09-55_7e8ca1b/layers/portal/pages/cinema/[citySlug]/[cinemaSlug]/index.vue").then(m => m.default || m)
  },
  {
    name: "cinema-citySlug-cinemaSlug-info___es",
    path: "/es/cine/:citySlug/:cinemaSlug/informacion",
    component: () => import("/home/forge/deployments/kinoheld/dev/2024-11-13_11-09-55_7e8ca1b/layers/portal/pages/cinema/[citySlug]/[cinemaSlug]/info.vue").then(m => m.default || m)
  },
  {
    name: "cinema-citySlug-cinemaSlug-movie-showGroupSlug___es",
    path: "movie/:showGroupSlug()",
    component: () => import("/home/forge/deployments/kinoheld/dev/2024-11-13_11-09-55_7e8ca1b/layers/portal/pages/cinema/[citySlug]/[cinemaSlug]/movie/[showGroupSlug].vue").then(m => m.default || m)
  },
  {
    name: "cinema-citySlug-cinemaSlug-program___es",
    path: "/es/cine/:citySlug/:cinemaSlug/sesiones",
    component: () => import("/home/forge/deployments/kinoheld/dev/2024-11-13_11-09-55_7e8ca1b/layers/portal/pages/cinema/[citySlug]/[cinemaSlug]/program.vue").then(m => m.default || m)
  },
  {
    name: "cinema-citySlug-cinemaSlug-shop___es",
    path: "shop",
    component: () => import("/home/forge/deployments/kinoheld/dev/2024-11-13_11-09-55_7e8ca1b/layers/portal/pages/cinema/[citySlug]/[cinemaSlug]/shop.vue").then(m => m.default || m)
  },
  {
    name: "cinema-citySlug-cinemaSlug-show-showSlug___es",
    path: "/es/cine/:citySlug/:cinemaSlug/evento/:showSlug",
    component: () => import("/home/forge/deployments/kinoheld/dev/2024-11-13_11-09-55_7e8ca1b/layers/portal/pages/cinema/[citySlug]/[cinemaSlug]/show/[showSlug].vue").then(m => m.default || m),
    children: [
  {
    name: "cinema-citySlug-cinemaSlug-show-showSlug-showName___es",
    path: "/es/cine/:citySlug/:cinemaSlug/evento/:showSlug/:showName",
    component: () => import("/home/forge/deployments/kinoheld/dev/2024-11-13_11-09-55_7e8ca1b/layers/portal/pages/cinema/[citySlug]/[cinemaSlug]/show/[showSlug]/[showName].vue").then(m => m.default || m)
  }
]
  },
  {
    name: "cinema-citySlug-cinemaSlug-vouchers___es",
    path: "/es/cine/:citySlug/:cinemaSlug/codigos",
    component: () => import("/home/forge/deployments/kinoheld/dev/2024-11-13_11-09-55_7e8ca1b/layers/portal/pages/cinema/[citySlug]/[cinemaSlug]/vouchers.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "cinema-citySlug___es",
    path: "",
    component: () => import("/home/forge/deployments/kinoheld/dev/2024-11-13_11-09-55_7e8ca1b/layers/portal/pages/cinema/[citySlug]/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "cinemas___de",
    path: "/kinos",
    component: () => import("/home/forge/deployments/kinoheld/dev/2024-11-13_11-09-55_7e8ca1b/layers/portal/pages/cinemas.vue").then(m => m.default || m)
  },
  {
    name: "cinemas___es",
    path: "/es/cines",
    component: () => import("/home/forge/deployments/kinoheld/dev/2024-11-13_11-09-55_7e8ca1b/layers/portal/pages/cinemas.vue").then(m => m.default || m)
  },
  {
    name: "cinemas___de",
    path: "/kinos",
    component: () => import("/home/forge/deployments/kinoheld/dev/2024-11-13_11-09-55_7e8ca1b/layers/portal/pages/cinemas.vue").then(m => m.default || m)
  },
  {
    name: "cinemas___es",
    path: "/es/cines",
    component: () => import("/home/forge/deployments/kinoheld/dev/2024-11-13_11-09-55_7e8ca1b/layers/portal/pages/cinemas.vue").then(m => m.default || m)
  },
  {
    name: "cinemas___de",
    path: "/kinos",
    component: () => import("/home/forge/deployments/kinoheld/dev/2024-11-13_11-09-55_7e8ca1b/layers/portal/pages/cinemas.vue").then(m => m.default || m)
  },
  {
    name: "cinemas___es",
    path: "/es/cines",
    component: () => import("/home/forge/deployments/kinoheld/dev/2024-11-13_11-09-55_7e8ca1b/layers/portal/pages/cinemas.vue").then(m => m.default || m)
  },
  {
    name: "cinemas___de",
    path: "/kinos",
    component: () => import("/home/forge/deployments/kinoheld/dev/2024-11-13_11-09-55_7e8ca1b/layers/portal/pages/cinemas.vue").then(m => m.default || m)
  },
  {
    name: "cinemas___es",
    path: "/es/cines",
    component: () => import("/home/forge/deployments/kinoheld/dev/2024-11-13_11-09-55_7e8ca1b/layers/portal/pages/cinemas.vue").then(m => m.default || m)
  },
  {
    name: "cinemas___de",
    path: "/kinos",
    component: () => import("/home/forge/deployments/kinoheld/dev/2024-11-13_11-09-55_7e8ca1b/layers/portal/pages/cinemas.vue").then(m => m.default || m)
  },
  {
    name: "cinemas___es",
    path: "/es/cines",
    component: () => import("/home/forge/deployments/kinoheld/dev/2024-11-13_11-09-55_7e8ca1b/layers/portal/pages/cinemas.vue").then(m => m.default || m)
  },
  {
    name: "cities___de",
    path: "/staedte",
    component: () => import("/home/forge/deployments/kinoheld/dev/2024-11-13_11-09-55_7e8ca1b/layers/portal/pages/cities.vue").then(m => m.default || m)
  },
  {
    name: "cities___es",
    path: "/es/ciudades",
    component: () => import("/home/forge/deployments/kinoheld/dev/2024-11-13_11-09-55_7e8ca1b/layers/portal/pages/cities.vue").then(m => m.default || m)
  },
  {
    name: "cities___de",
    path: "/staedte",
    component: () => import("/home/forge/deployments/kinoheld/dev/2024-11-13_11-09-55_7e8ca1b/layers/portal/pages/cities.vue").then(m => m.default || m)
  },
  {
    name: "cities___es",
    path: "/es/ciudades",
    component: () => import("/home/forge/deployments/kinoheld/dev/2024-11-13_11-09-55_7e8ca1b/layers/portal/pages/cities.vue").then(m => m.default || m)
  },
  {
    name: "cities___de",
    path: "/staedte",
    component: () => import("/home/forge/deployments/kinoheld/dev/2024-11-13_11-09-55_7e8ca1b/layers/portal/pages/cities.vue").then(m => m.default || m)
  },
  {
    name: "cities___es",
    path: "/es/ciudades",
    component: () => import("/home/forge/deployments/kinoheld/dev/2024-11-13_11-09-55_7e8ca1b/layers/portal/pages/cities.vue").then(m => m.default || m)
  },
  {
    name: "cities___de",
    path: "/staedte",
    component: () => import("/home/forge/deployments/kinoheld/dev/2024-11-13_11-09-55_7e8ca1b/layers/portal/pages/cities.vue").then(m => m.default || m)
  },
  {
    name: "cities___es",
    path: "/es/ciudades",
    component: () => import("/home/forge/deployments/kinoheld/dev/2024-11-13_11-09-55_7e8ca1b/layers/portal/pages/cities.vue").then(m => m.default || m)
  },
  {
    name: "cities___de",
    path: "/staedte",
    component: () => import("/home/forge/deployments/kinoheld/dev/2024-11-13_11-09-55_7e8ca1b/layers/portal/pages/cities.vue").then(m => m.default || m)
  },
  {
    name: "cities___es",
    path: "/es/ciudades",
    component: () => import("/home/forge/deployments/kinoheld/dev/2024-11-13_11-09-55_7e8ca1b/layers/portal/pages/cities.vue").then(m => m.default || m)
  },
  {
    name: "index___de",
    path: "/",
    component: () => import("/home/forge/deployments/kinoheld/dev/2024-11-13_11-09-55_7e8ca1b/layers/portal/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "index___es",
    path: "/es",
    component: () => import("/home/forge/deployments/kinoheld/dev/2024-11-13_11-09-55_7e8ca1b/layers/portal/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "index___de",
    path: "/",
    component: () => import("/home/forge/deployments/kinoheld/dev/2024-11-13_11-09-55_7e8ca1b/layers/portal/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "index___es",
    path: "/es",
    component: () => import("/home/forge/deployments/kinoheld/dev/2024-11-13_11-09-55_7e8ca1b/layers/portal/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "index___de",
    path: "/",
    component: () => import("/home/forge/deployments/kinoheld/dev/2024-11-13_11-09-55_7e8ca1b/layers/portal/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "index___es",
    path: "/es",
    component: () => import("/home/forge/deployments/kinoheld/dev/2024-11-13_11-09-55_7e8ca1b/layers/portal/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "index___de",
    path: "/",
    component: () => import("/home/forge/deployments/kinoheld/dev/2024-11-13_11-09-55_7e8ca1b/layers/portal/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "index___es",
    path: "/es",
    component: () => import("/home/forge/deployments/kinoheld/dev/2024-11-13_11-09-55_7e8ca1b/layers/portal/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "index___de",
    path: "/",
    component: () => import("/home/forge/deployments/kinoheld/dev/2024-11-13_11-09-55_7e8ca1b/layers/portal/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "index___es",
    path: "/es",
    component: () => import("/home/forge/deployments/kinoheld/dev/2024-11-13_11-09-55_7e8ca1b/layers/portal/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "movie-movieSlug___de",
    path: "/film/:movieSlug",
    component: () => import("/home/forge/deployments/kinoheld/dev/2024-11-13_11-09-55_7e8ca1b/layers/portal/pages/movie/[movieSlug].vue").then(m => m.default || m)
  },
  {
    name: "movie-movieSlug___es",
    path: "/es/pelicula/:movieSlug",
    component: () => import("/home/forge/deployments/kinoheld/dev/2024-11-13_11-09-55_7e8ca1b/layers/portal/pages/movie/[movieSlug].vue").then(m => m.default || m)
  },
  {
    name: "movie-movieSlug___de",
    path: "/film/:movieSlug",
    component: () => import("/home/forge/deployments/kinoheld/dev/2024-11-13_11-09-55_7e8ca1b/layers/portal/pages/movie/[movieSlug].vue").then(m => m.default || m)
  },
  {
    name: "movie-movieSlug___es",
    path: "/es/pelicula/:movieSlug",
    component: () => import("/home/forge/deployments/kinoheld/dev/2024-11-13_11-09-55_7e8ca1b/layers/portal/pages/movie/[movieSlug].vue").then(m => m.default || m)
  },
  {
    name: "movie-movieSlug___de",
    path: "/film/:movieSlug",
    component: () => import("/home/forge/deployments/kinoheld/dev/2024-11-13_11-09-55_7e8ca1b/layers/portal/pages/movie/[movieSlug].vue").then(m => m.default || m)
  },
  {
    name: "movie-movieSlug___es",
    path: "/es/pelicula/:movieSlug",
    component: () => import("/home/forge/deployments/kinoheld/dev/2024-11-13_11-09-55_7e8ca1b/layers/portal/pages/movie/[movieSlug].vue").then(m => m.default || m)
  },
  {
    name: "movie-movieSlug___de",
    path: "/film/:movieSlug",
    component: () => import("/home/forge/deployments/kinoheld/dev/2024-11-13_11-09-55_7e8ca1b/layers/portal/pages/movie/[movieSlug].vue").then(m => m.default || m)
  },
  {
    name: "movie-movieSlug___es",
    path: "/es/pelicula/:movieSlug",
    component: () => import("/home/forge/deployments/kinoheld/dev/2024-11-13_11-09-55_7e8ca1b/layers/portal/pages/movie/[movieSlug].vue").then(m => m.default || m)
  },
  {
    name: "movie-movieSlug___de",
    path: "/film/:movieSlug",
    component: () => import("/home/forge/deployments/kinoheld/dev/2024-11-13_11-09-55_7e8ca1b/layers/portal/pages/movie/[movieSlug].vue").then(m => m.default || m)
  },
  {
    name: "movie-movieSlug___es",
    path: "/es/pelicula/:movieSlug",
    component: () => import("/home/forge/deployments/kinoheld/dev/2024-11-13_11-09-55_7e8ca1b/layers/portal/pages/movie/[movieSlug].vue").then(m => m.default || m)
  },
  {
    name: _91genreSlug_93oyRAu5w2rQMeta?.name,
    path: "/filme/genre/:genreSlug",
    component: () => import("/home/forge/deployments/kinoheld/dev/2024-11-13_11-09-55_7e8ca1b/layers/portal/pages/movies/genre/[genreSlug].vue").then(m => m.default || m),
    children: [
  {
    name: "movies-genre-genreSlug___de",
    path: "",
    component: () => import("/home/forge/deployments/kinoheld/dev/2024-11-13_11-09-55_7e8ca1b/layers/portal/pages/movies/genre/[genreSlug]/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: _91genreSlug_93oyRAu5w2rQMeta?.name,
    path: "/es/peliculas/generos/:genreSlug",
    component: () => import("/home/forge/deployments/kinoheld/dev/2024-11-13_11-09-55_7e8ca1b/layers/portal/pages/movies/genre/[genreSlug].vue").then(m => m.default || m),
    children: [
  {
    name: "movies-genre-genreSlug___es",
    path: "",
    component: () => import("/home/forge/deployments/kinoheld/dev/2024-11-13_11-09-55_7e8ca1b/layers/portal/pages/movies/genre/[genreSlug]/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: _91genreSlug_93oyRAu5w2rQMeta?.name,
    path: "/filme/genre/:genreSlug",
    component: () => import("/home/forge/deployments/kinoheld/dev/2024-11-13_11-09-55_7e8ca1b/layers/portal/pages/movies/genre/[genreSlug].vue").then(m => m.default || m),
    children: [
  {
    name: "movies-genre-genreSlug___de",
    path: "",
    component: () => import("/home/forge/deployments/kinoheld/dev/2024-11-13_11-09-55_7e8ca1b/layers/portal/pages/movies/genre/[genreSlug]/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: _91genreSlug_93oyRAu5w2rQMeta?.name,
    path: "/es/peliculas/generos/:genreSlug",
    component: () => import("/home/forge/deployments/kinoheld/dev/2024-11-13_11-09-55_7e8ca1b/layers/portal/pages/movies/genre/[genreSlug].vue").then(m => m.default || m),
    children: [
  {
    name: "movies-genre-genreSlug___es",
    path: "",
    component: () => import("/home/forge/deployments/kinoheld/dev/2024-11-13_11-09-55_7e8ca1b/layers/portal/pages/movies/genre/[genreSlug]/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: _91genreSlug_93oyRAu5w2rQMeta?.name,
    path: "/filme/genre/:genreSlug",
    component: () => import("/home/forge/deployments/kinoheld/dev/2024-11-13_11-09-55_7e8ca1b/layers/portal/pages/movies/genre/[genreSlug].vue").then(m => m.default || m),
    children: [
  {
    name: "movies-genre-genreSlug___de",
    path: "",
    component: () => import("/home/forge/deployments/kinoheld/dev/2024-11-13_11-09-55_7e8ca1b/layers/portal/pages/movies/genre/[genreSlug]/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: _91genreSlug_93oyRAu5w2rQMeta?.name,
    path: "/es/peliculas/generos/:genreSlug",
    component: () => import("/home/forge/deployments/kinoheld/dev/2024-11-13_11-09-55_7e8ca1b/layers/portal/pages/movies/genre/[genreSlug].vue").then(m => m.default || m),
    children: [
  {
    name: "movies-genre-genreSlug___es",
    path: "",
    component: () => import("/home/forge/deployments/kinoheld/dev/2024-11-13_11-09-55_7e8ca1b/layers/portal/pages/movies/genre/[genreSlug]/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: _91genreSlug_93oyRAu5w2rQMeta?.name,
    path: "/filme/genre/:genreSlug",
    component: () => import("/home/forge/deployments/kinoheld/dev/2024-11-13_11-09-55_7e8ca1b/layers/portal/pages/movies/genre/[genreSlug].vue").then(m => m.default || m),
    children: [
  {
    name: "movies-genre-genreSlug___de",
    path: "",
    component: () => import("/home/forge/deployments/kinoheld/dev/2024-11-13_11-09-55_7e8ca1b/layers/portal/pages/movies/genre/[genreSlug]/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: _91genreSlug_93oyRAu5w2rQMeta?.name,
    path: "/es/peliculas/generos/:genreSlug",
    component: () => import("/home/forge/deployments/kinoheld/dev/2024-11-13_11-09-55_7e8ca1b/layers/portal/pages/movies/genre/[genreSlug].vue").then(m => m.default || m),
    children: [
  {
    name: "movies-genre-genreSlug___es",
    path: "",
    component: () => import("/home/forge/deployments/kinoheld/dev/2024-11-13_11-09-55_7e8ca1b/layers/portal/pages/movies/genre/[genreSlug]/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: _91genreSlug_93oyRAu5w2rQMeta?.name,
    path: "/filme/genre/:genreSlug",
    component: () => import("/home/forge/deployments/kinoheld/dev/2024-11-13_11-09-55_7e8ca1b/layers/portal/pages/movies/genre/[genreSlug].vue").then(m => m.default || m),
    children: [
  {
    name: "movies-genre-genreSlug___de",
    path: "",
    component: () => import("/home/forge/deployments/kinoheld/dev/2024-11-13_11-09-55_7e8ca1b/layers/portal/pages/movies/genre/[genreSlug]/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: _91genreSlug_93oyRAu5w2rQMeta?.name,
    path: "/es/peliculas/generos/:genreSlug",
    component: () => import("/home/forge/deployments/kinoheld/dev/2024-11-13_11-09-55_7e8ca1b/layers/portal/pages/movies/genre/[genreSlug].vue").then(m => m.default || m),
    children: [
  {
    name: "movies-genre-genreSlug___es",
    path: "",
    component: () => import("/home/forge/deployments/kinoheld/dev/2024-11-13_11-09-55_7e8ca1b/layers/portal/pages/movies/genre/[genreSlug]/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "movies-genres___de",
    path: "/filme/genres",
    component: () => import("/home/forge/deployments/kinoheld/dev/2024-11-13_11-09-55_7e8ca1b/layers/portal/pages/movies/genres.vue").then(m => m.default || m)
  },
  {
    name: "movies-genres___es",
    path: "/es/peliculas/generos",
    component: () => import("/home/forge/deployments/kinoheld/dev/2024-11-13_11-09-55_7e8ca1b/layers/portal/pages/movies/genres.vue").then(m => m.default || m)
  },
  {
    name: "movies-genres___de",
    path: "/filme/genres",
    component: () => import("/home/forge/deployments/kinoheld/dev/2024-11-13_11-09-55_7e8ca1b/layers/portal/pages/movies/genres.vue").then(m => m.default || m)
  },
  {
    name: "movies-genres___es",
    path: "/es/peliculas/generos",
    component: () => import("/home/forge/deployments/kinoheld/dev/2024-11-13_11-09-55_7e8ca1b/layers/portal/pages/movies/genres.vue").then(m => m.default || m)
  },
  {
    name: "movies-genres___de",
    path: "/filme/genres",
    component: () => import("/home/forge/deployments/kinoheld/dev/2024-11-13_11-09-55_7e8ca1b/layers/portal/pages/movies/genres.vue").then(m => m.default || m)
  },
  {
    name: "movies-genres___es",
    path: "/es/peliculas/generos",
    component: () => import("/home/forge/deployments/kinoheld/dev/2024-11-13_11-09-55_7e8ca1b/layers/portal/pages/movies/genres.vue").then(m => m.default || m)
  },
  {
    name: "movies-genres___de",
    path: "/filme/genres",
    component: () => import("/home/forge/deployments/kinoheld/dev/2024-11-13_11-09-55_7e8ca1b/layers/portal/pages/movies/genres.vue").then(m => m.default || m)
  },
  {
    name: "movies-genres___es",
    path: "/es/peliculas/generos",
    component: () => import("/home/forge/deployments/kinoheld/dev/2024-11-13_11-09-55_7e8ca1b/layers/portal/pages/movies/genres.vue").then(m => m.default || m)
  },
  {
    name: "movies-genres___de",
    path: "/filme/genres",
    component: () => import("/home/forge/deployments/kinoheld/dev/2024-11-13_11-09-55_7e8ca1b/layers/portal/pages/movies/genres.vue").then(m => m.default || m)
  },
  {
    name: "movies-genres___es",
    path: "/es/peliculas/generos",
    component: () => import("/home/forge/deployments/kinoheld/dev/2024-11-13_11-09-55_7e8ca1b/layers/portal/pages/movies/genres.vue").then(m => m.default || m)
  },
  {
    name: "movies___de",
    path: "/filme",
    component: () => import("/home/forge/deployments/kinoheld/dev/2024-11-13_11-09-55_7e8ca1b/layers/portal/pages/movies/index.vue").then(m => m.default || m)
  },
  {
    name: "movies___es",
    path: "/es/peliculas",
    component: () => import("/home/forge/deployments/kinoheld/dev/2024-11-13_11-09-55_7e8ca1b/layers/portal/pages/movies/index.vue").then(m => m.default || m)
  },
  {
    name: "movies___de",
    path: "/filme",
    component: () => import("/home/forge/deployments/kinoheld/dev/2024-11-13_11-09-55_7e8ca1b/layers/portal/pages/movies/index.vue").then(m => m.default || m)
  },
  {
    name: "movies___es",
    path: "/es/peliculas",
    component: () => import("/home/forge/deployments/kinoheld/dev/2024-11-13_11-09-55_7e8ca1b/layers/portal/pages/movies/index.vue").then(m => m.default || m)
  },
  {
    name: "movies___de",
    path: "/filme",
    component: () => import("/home/forge/deployments/kinoheld/dev/2024-11-13_11-09-55_7e8ca1b/layers/portal/pages/movies/index.vue").then(m => m.default || m)
  },
  {
    name: "movies___es",
    path: "/es/peliculas",
    component: () => import("/home/forge/deployments/kinoheld/dev/2024-11-13_11-09-55_7e8ca1b/layers/portal/pages/movies/index.vue").then(m => m.default || m)
  },
  {
    name: "movies___de",
    path: "/filme",
    component: () => import("/home/forge/deployments/kinoheld/dev/2024-11-13_11-09-55_7e8ca1b/layers/portal/pages/movies/index.vue").then(m => m.default || m)
  },
  {
    name: "movies___es",
    path: "/es/peliculas",
    component: () => import("/home/forge/deployments/kinoheld/dev/2024-11-13_11-09-55_7e8ca1b/layers/portal/pages/movies/index.vue").then(m => m.default || m)
  },
  {
    name: "movies___de",
    path: "/filme",
    component: () => import("/home/forge/deployments/kinoheld/dev/2024-11-13_11-09-55_7e8ca1b/layers/portal/pages/movies/index.vue").then(m => m.default || m)
  },
  {
    name: "movies___es",
    path: "/es/peliculas",
    component: () => import("/home/forge/deployments/kinoheld/dev/2024-11-13_11-09-55_7e8ca1b/layers/portal/pages/movies/index.vue").then(m => m.default || m)
  },
  {
    name: _91personSlug_93MrPQzxEk41Meta?.name,
    path: "/person/:personSlug",
    component: () => import("/home/forge/deployments/kinoheld/dev/2024-11-13_11-09-55_7e8ca1b/layers/portal/pages/person/[personSlug].vue").then(m => m.default || m),
    children: [
  {
    name: "person-personSlug___de",
    path: "",
    component: () => import("/home/forge/deployments/kinoheld/dev/2024-11-13_11-09-55_7e8ca1b/layers/portal/pages/person/[personSlug]/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: _91personSlug_93MrPQzxEk41Meta?.name,
    path: "/es/persona/:personSlug",
    component: () => import("/home/forge/deployments/kinoheld/dev/2024-11-13_11-09-55_7e8ca1b/layers/portal/pages/person/[personSlug].vue").then(m => m.default || m),
    children: [
  {
    name: "person-personSlug___es",
    path: "",
    component: () => import("/home/forge/deployments/kinoheld/dev/2024-11-13_11-09-55_7e8ca1b/layers/portal/pages/person/[personSlug]/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: _91personSlug_93MrPQzxEk41Meta?.name,
    path: "/person/:personSlug",
    component: () => import("/home/forge/deployments/kinoheld/dev/2024-11-13_11-09-55_7e8ca1b/layers/portal/pages/person/[personSlug].vue").then(m => m.default || m),
    children: [
  {
    name: "person-personSlug___de",
    path: "",
    component: () => import("/home/forge/deployments/kinoheld/dev/2024-11-13_11-09-55_7e8ca1b/layers/portal/pages/person/[personSlug]/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: _91personSlug_93MrPQzxEk41Meta?.name,
    path: "/es/persona/:personSlug",
    component: () => import("/home/forge/deployments/kinoheld/dev/2024-11-13_11-09-55_7e8ca1b/layers/portal/pages/person/[personSlug].vue").then(m => m.default || m),
    children: [
  {
    name: "person-personSlug___es",
    path: "",
    component: () => import("/home/forge/deployments/kinoheld/dev/2024-11-13_11-09-55_7e8ca1b/layers/portal/pages/person/[personSlug]/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: _91personSlug_93MrPQzxEk41Meta?.name,
    path: "/person/:personSlug",
    component: () => import("/home/forge/deployments/kinoheld/dev/2024-11-13_11-09-55_7e8ca1b/layers/portal/pages/person/[personSlug].vue").then(m => m.default || m),
    children: [
  {
    name: "person-personSlug___de",
    path: "",
    component: () => import("/home/forge/deployments/kinoheld/dev/2024-11-13_11-09-55_7e8ca1b/layers/portal/pages/person/[personSlug]/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: _91personSlug_93MrPQzxEk41Meta?.name,
    path: "/es/persona/:personSlug",
    component: () => import("/home/forge/deployments/kinoheld/dev/2024-11-13_11-09-55_7e8ca1b/layers/portal/pages/person/[personSlug].vue").then(m => m.default || m),
    children: [
  {
    name: "person-personSlug___es",
    path: "",
    component: () => import("/home/forge/deployments/kinoheld/dev/2024-11-13_11-09-55_7e8ca1b/layers/portal/pages/person/[personSlug]/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: _91personSlug_93MrPQzxEk41Meta?.name,
    path: "/person/:personSlug",
    component: () => import("/home/forge/deployments/kinoheld/dev/2024-11-13_11-09-55_7e8ca1b/layers/portal/pages/person/[personSlug].vue").then(m => m.default || m),
    children: [
  {
    name: "person-personSlug___de",
    path: "",
    component: () => import("/home/forge/deployments/kinoheld/dev/2024-11-13_11-09-55_7e8ca1b/layers/portal/pages/person/[personSlug]/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: _91personSlug_93MrPQzxEk41Meta?.name,
    path: "/es/persona/:personSlug",
    component: () => import("/home/forge/deployments/kinoheld/dev/2024-11-13_11-09-55_7e8ca1b/layers/portal/pages/person/[personSlug].vue").then(m => m.default || m),
    children: [
  {
    name: "person-personSlug___es",
    path: "",
    component: () => import("/home/forge/deployments/kinoheld/dev/2024-11-13_11-09-55_7e8ca1b/layers/portal/pages/person/[personSlug]/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: _91personSlug_93MrPQzxEk41Meta?.name,
    path: "/person/:personSlug",
    component: () => import("/home/forge/deployments/kinoheld/dev/2024-11-13_11-09-55_7e8ca1b/layers/portal/pages/person/[personSlug].vue").then(m => m.default || m),
    children: [
  {
    name: "person-personSlug___de",
    path: "",
    component: () => import("/home/forge/deployments/kinoheld/dev/2024-11-13_11-09-55_7e8ca1b/layers/portal/pages/person/[personSlug]/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: _91personSlug_93MrPQzxEk41Meta?.name,
    path: "/es/persona/:personSlug",
    component: () => import("/home/forge/deployments/kinoheld/dev/2024-11-13_11-09-55_7e8ca1b/layers/portal/pages/person/[personSlug].vue").then(m => m.default || m),
    children: [
  {
    name: "person-personSlug___es",
    path: "",
    component: () => import("/home/forge/deployments/kinoheld/dev/2024-11-13_11-09-55_7e8ca1b/layers/portal/pages/person/[personSlug]/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "persons___de",
    path: "/personen",
    component: () => import("/home/forge/deployments/kinoheld/dev/2024-11-13_11-09-55_7e8ca1b/layers/portal/pages/persons.vue").then(m => m.default || m)
  },
  {
    name: "persons___es",
    path: "/es/personas",
    component: () => import("/home/forge/deployments/kinoheld/dev/2024-11-13_11-09-55_7e8ca1b/layers/portal/pages/persons.vue").then(m => m.default || m)
  },
  {
    name: "persons___de",
    path: "/personen",
    component: () => import("/home/forge/deployments/kinoheld/dev/2024-11-13_11-09-55_7e8ca1b/layers/portal/pages/persons.vue").then(m => m.default || m)
  },
  {
    name: "persons___es",
    path: "/es/personas",
    component: () => import("/home/forge/deployments/kinoheld/dev/2024-11-13_11-09-55_7e8ca1b/layers/portal/pages/persons.vue").then(m => m.default || m)
  },
  {
    name: "persons___de",
    path: "/personen",
    component: () => import("/home/forge/deployments/kinoheld/dev/2024-11-13_11-09-55_7e8ca1b/layers/portal/pages/persons.vue").then(m => m.default || m)
  },
  {
    name: "persons___es",
    path: "/es/personas",
    component: () => import("/home/forge/deployments/kinoheld/dev/2024-11-13_11-09-55_7e8ca1b/layers/portal/pages/persons.vue").then(m => m.default || m)
  },
  {
    name: "persons___de",
    path: "/personen",
    component: () => import("/home/forge/deployments/kinoheld/dev/2024-11-13_11-09-55_7e8ca1b/layers/portal/pages/persons.vue").then(m => m.default || m)
  },
  {
    name: "persons___es",
    path: "/es/personas",
    component: () => import("/home/forge/deployments/kinoheld/dev/2024-11-13_11-09-55_7e8ca1b/layers/portal/pages/persons.vue").then(m => m.default || m)
  },
  {
    name: "persons___de",
    path: "/personen",
    component: () => import("/home/forge/deployments/kinoheld/dev/2024-11-13_11-09-55_7e8ca1b/layers/portal/pages/persons.vue").then(m => m.default || m)
  },
  {
    name: "persons___es",
    path: "/es/personas",
    component: () => import("/home/forge/deployments/kinoheld/dev/2024-11-13_11-09-55_7e8ca1b/layers/portal/pages/persons.vue").then(m => m.default || m)
  },
  {
    name: "search___de",
    path: "/suche",
    component: () => import("/home/forge/deployments/kinoheld/dev/2024-11-13_11-09-55_7e8ca1b/layers/portal/pages/search.vue").then(m => m.default || m)
  },
  {
    name: "search___es",
    path: "/es/buscar",
    component: () => import("/home/forge/deployments/kinoheld/dev/2024-11-13_11-09-55_7e8ca1b/layers/portal/pages/search.vue").then(m => m.default || m)
  },
  {
    name: "search___de",
    path: "/suche",
    component: () => import("/home/forge/deployments/kinoheld/dev/2024-11-13_11-09-55_7e8ca1b/layers/portal/pages/search.vue").then(m => m.default || m)
  },
  {
    name: "search___es",
    path: "/es/buscar",
    component: () => import("/home/forge/deployments/kinoheld/dev/2024-11-13_11-09-55_7e8ca1b/layers/portal/pages/search.vue").then(m => m.default || m)
  },
  {
    name: "search___de",
    path: "/suche",
    component: () => import("/home/forge/deployments/kinoheld/dev/2024-11-13_11-09-55_7e8ca1b/layers/portal/pages/search.vue").then(m => m.default || m)
  },
  {
    name: "search___es",
    path: "/es/buscar",
    component: () => import("/home/forge/deployments/kinoheld/dev/2024-11-13_11-09-55_7e8ca1b/layers/portal/pages/search.vue").then(m => m.default || m)
  },
  {
    name: "search___de",
    path: "/suche",
    component: () => import("/home/forge/deployments/kinoheld/dev/2024-11-13_11-09-55_7e8ca1b/layers/portal/pages/search.vue").then(m => m.default || m)
  },
  {
    name: "search___es",
    path: "/es/buscar",
    component: () => import("/home/forge/deployments/kinoheld/dev/2024-11-13_11-09-55_7e8ca1b/layers/portal/pages/search.vue").then(m => m.default || m)
  },
  {
    name: "search___de",
    path: "/suche",
    component: () => import("/home/forge/deployments/kinoheld/dev/2024-11-13_11-09-55_7e8ca1b/layers/portal/pages/search.vue").then(m => m.default || m)
  },
  {
    name: "search___es",
    path: "/es/buscar",
    component: () => import("/home/forge/deployments/kinoheld/dev/2024-11-13_11-09-55_7e8ca1b/layers/portal/pages/search.vue").then(m => m.default || m)
  },
  {
    name: "vouchers___de",
    path: "/gutscheine",
    component: () => import("/home/forge/deployments/kinoheld/dev/2024-11-13_11-09-55_7e8ca1b/layers/portal/pages/vouchers.vue").then(m => m.default || m)
  },
  {
    name: "vouchers___es",
    path: "/es/codigos",
    component: () => import("/home/forge/deployments/kinoheld/dev/2024-11-13_11-09-55_7e8ca1b/layers/portal/pages/vouchers.vue").then(m => m.default || m)
  },
  {
    name: "vouchers___de",
    path: "/gutscheine",
    component: () => import("/home/forge/deployments/kinoheld/dev/2024-11-13_11-09-55_7e8ca1b/layers/portal/pages/vouchers.vue").then(m => m.default || m)
  },
  {
    name: "vouchers___es",
    path: "/es/codigos",
    component: () => import("/home/forge/deployments/kinoheld/dev/2024-11-13_11-09-55_7e8ca1b/layers/portal/pages/vouchers.vue").then(m => m.default || m)
  },
  {
    name: "vouchers___de",
    path: "/gutscheine",
    component: () => import("/home/forge/deployments/kinoheld/dev/2024-11-13_11-09-55_7e8ca1b/layers/portal/pages/vouchers.vue").then(m => m.default || m)
  },
  {
    name: "vouchers___es",
    path: "/es/codigos",
    component: () => import("/home/forge/deployments/kinoheld/dev/2024-11-13_11-09-55_7e8ca1b/layers/portal/pages/vouchers.vue").then(m => m.default || m)
  },
  {
    name: "vouchers___de",
    path: "/gutscheine",
    component: () => import("/home/forge/deployments/kinoheld/dev/2024-11-13_11-09-55_7e8ca1b/layers/portal/pages/vouchers.vue").then(m => m.default || m)
  },
  {
    name: "vouchers___es",
    path: "/es/codigos",
    component: () => import("/home/forge/deployments/kinoheld/dev/2024-11-13_11-09-55_7e8ca1b/layers/portal/pages/vouchers.vue").then(m => m.default || m)
  },
  {
    name: "vouchers___de",
    path: "/gutscheine",
    component: () => import("/home/forge/deployments/kinoheld/dev/2024-11-13_11-09-55_7e8ca1b/layers/portal/pages/vouchers.vue").then(m => m.default || m)
  },
  {
    name: "vouchers___es",
    path: "/es/codigos",
    component: () => import("/home/forge/deployments/kinoheld/dev/2024-11-13_11-09-55_7e8ca1b/layers/portal/pages/vouchers.vue").then(m => m.default || m)
  }
]